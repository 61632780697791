import { render, staticRenderFns } from "./-education.vue?vue&type=template&id=590a02e0&scoped=true"
import script from "./-education.vue?vue&type=script&lang=js"
export * from "./-education.vue?vue&type=script&lang=js"
import style0 from "./-education.vue?vue&type=style&index=0&id=590a02e0&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "590a02e0",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NewClassSection: require('/home/runner/work/gguge.web/gguge.web/components/new-home/common/new-class-section/NewClassSection.vue').default})
